import { createSelector } from '@ngrx/store';
import { MclState } from '.';
import { OperatorMonitorState } from '..';

const selectMaintenance = (state: OperatorMonitorState) => state.mcl;

export namespace MaintenanceSelectors {
  export const getMaintenanceChecklist = createSelector(
    selectMaintenance,
    (state) => denormalizeCheckList(state),
  );

  export const getIsMaintenanceChecklistLoading = createSelector(
    selectMaintenance,
    (state) => state.isChecklistLoading,
  );
  export const getHasMaintenanceChecklistFailed = createSelector(
    selectMaintenance,
    (state) => state.hasChecklistFaield,
  );
  export const getMaintenanceHistoryComments = createSelector(
    selectMaintenance,
    (state) => state.maintenanceComments,
  );
}

function denormalizeCheckList(state: MclState) {
  return state.checklist
    ? {
        ...state.checklist,
        cleaning: state.checklist.cleaning
          ? state.checklist.cleaning
              .map((x) => x.id)
              .filter((id): id is number => !!id)
              .map((id) => state.checklistItems[id])
          : null,
        visual: state.checklist.visual
          ? state.checklist.visual
              .map((x) => x.id)
              .filter((id): id is number => !!id)
              .map((id) => state.checklistItems[id])
          : null,
        lubrication: state.checklist.lubrication
          ? state.checklist.lubrication
              .map((x) => x.id)
              .filter((id): id is number => !!id)
              .map((id) => state.checklistItems[id])
          : null,
        checklist: state.checklist.checklist
          ? state.checklist.checklist
              .map((x) => x.id)
              .filter((id): id is number => !!id)
              .map((id) => state.checklistItems[id])
          : null,
        requestState: state.requestState,
      }
    : undefined;
}
