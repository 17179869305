import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CheckService } from '@paldesk/shared-lib/data-access/maintenance-service-generated';
import { filterTruthy } from '@shared-lib/rxjs';
import { OperatorMonitorState } from '..';
import { ErrorInfoComponent } from '../../components/error-info/error-info.component';
import { HeaderSelectors } from '../header';
import { MaintenanceActions } from './maintenance.actions';

@Injectable()
export class MclEffects {
  protected actions$: Actions = inject(Actions);
  private store: Store<OperatorMonitorState> = inject(Store);

  loadMaintenanceChecklist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.LoadMaintenanceChecklist),
      switchMap(({ payload }) =>
        this.checkService.getMaintenanceCheckList(payload).pipe(
          map((data) =>
            MaintenanceActions.LoadMaintenanceChecklistSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            MaintenanceActions.LoadMaintenanceChecklistError(),
          ]),
        ),
      ),
    ),
  );

  finishChecklist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.FinishCheckList),
      withLatestFrom(
        this.store.pipe(
          select(HeaderSelectors.getSelectedEquipmentNumber),
          filterTruthy(),
        ),
      ),
      switchMap(([{ payload }, equipmentNumber]) =>
        this.checkService.addMaintenanceCheck(equipmentNumber, payload).pipe(
          map(() => {
            this.router.navigate(['./maintenance']);
            return MaintenanceActions.FinishCheckListSuccess();
          }),
          catchError(() => {
            this.dialog.open(ErrorInfoComponent);
            return of(MaintenanceActions.FinishCheckListError());
          }),
        ),
      ),
    ),
  );

  prepareChecklist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.PrepareMaintenanceChecklist),
      switchMap(() =>
        this.checkService.prepareChecklist().pipe(
          map(() => MaintenanceActions.PrepareMaintenanceChecklistSuccess()),
          catchError(() => [
            MaintenanceActions.PrepareMaintenanceChecklistError(),
          ]),
        ),
      ),
    ),
  );

  PrepareMaintenanceChecklistSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaintenanceActions.PrepareMaintenanceChecklistSuccess),
        tap(() =>
          sessionStorage.setItem('prepareMaintenanceChecklistCalled', 'true'),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private checkService: CheckService,
    private router: Router,
    private dialog: MatDialog,
  ) {}
}
