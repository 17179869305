import { createAction, props } from '@ngrx/store';
import {
  GetMaintenanceChecklistCommandResult,
  AddMaintenanceCheck,
  MclComment,
} from '@paldesk/shared-lib/data-access/maintenance-service-generated';

export namespace MaintenanceActions {
  // checklist
  export const PrepareMaintenanceChecklist = createAction(
    '[Maintenance] PREPARE_MAINTENANCE_CHECKLIST',
  );
  export const PrepareMaintenanceChecklistSuccess = createAction(
    '[Maintenance] PREPARE_MAINTENANCE_CHECKLIST_SUCCESS',
  );
  export const PrepareMaintenanceChecklistError = createAction(
    '[Maintenance] PREPARE_MAINTENANCE_CHECKLIST_ERROR',
  );
  export const LoadMaintenanceChecklist = createAction(
    '[Maintenance] LOAD_MAINTENANCE_CHECKLIST',
    props<{ payload: string }>(),
  );
  export const LoadMaintenanceChecklistSuccess = createAction(
    '[Maintenance] LOAD_MAINTENANCE_CHECKLIST_SUCCESS',
    props<{ payload: GetMaintenanceChecklistCommandResult }>(),
  );
  export const LoadMaintenanceChecklistError = createAction(
    '[Maintenance] LOAD_MAINTENANCE_CHECKLIST_ERROR',
  );
  export const ToggleStep = createAction(
    '[Maintenance] LOAD_TOGGLE_STEP',
    props<{ payload: number }>(),
  );

  // finish checklist
  export const FinishCheckList = createAction(
    '[Maintenance] FINISH_CHECKLIST',
    props<{ payload: AddMaintenanceCheck }>(),
  );
  export const FinishCheckListSuccess = createAction(
    '[Maintenance] FINISH_CHECKLIST_LIST_SUCCESS',
  );
  export const FinishCheckListError = createAction(
    '[Maintenance] FINISH_CHECKLIST_LIST_ERROR',
  );
  export const AddAndUpdateComment = createAction(
    '[Maintenance] ADD_AND_UPDATE_COMMENT',
    props<{ comment: MclComment; isEdit?: boolean }>(),
  );
}
