import { createReducer, on } from '@ngrx/store';
import { MaintenanceActions } from './maintenance.actions';
import {
  MaintenanceCheckResponse,
  RequestStateDto,
  GetMaintenanceChecklistCommandResult,
  MclComment,
} from '@paldesk/shared-lib/data-access/maintenance-service-generated';
export interface MclState {
  checklist?: GetMaintenanceChecklistCommandResult;
  isChecklistLoading: boolean;
  hasChecklistFaield: boolean;

  checklistItems: { [id: number]: MaintenanceCheckResponse };
  requestState?: RequestStateDto;
  maintenanceComments: MclComment[];
}

export const initialState: MclState = {
  checklist: undefined,
  checklistItems: [],
  requestState: undefined,
  isChecklistLoading: true,
  hasChecklistFaield: false,
  maintenanceComments: [],
};

const _maintenanceReducer = createReducer(
  initialState,
  on(MaintenanceActions.LoadMaintenanceChecklist, (state) => ({
    ...state,
    checklist: initialState.checklist,
    isChecklistLoading: true,
    hasChecklistFaield: false,
  })),
  on(
    MaintenanceActions.LoadMaintenanceChecklistSuccess,
    (state, { payload }) => ({
      ...state,
      checklist: payload,
      checklistItems: getChecklistItems(payload),
      requestState: payload.mcl_request_state,
      isChecklistLoading: false,
      hasChecklistFaield: false,
      maintenanceComments: initialState.maintenanceComments,
    }),
  ),
  on(MaintenanceActions.LoadMaintenanceChecklistError, (state) => ({
    ...state,
    isChecklistLoading: false,
    hasChecklistFaield: true,
  })),
  on(MaintenanceActions.ToggleStep, (state, { payload }) => ({
    ...state,
    checklistItems: toggleChecklistItemDone(payload, state),
  })),
  on(
    MaintenanceActions.AddAndUpdateComment,
    (state, payload: { comment: MclComment; isEdit?: boolean }) => ({
      ...state,
      maintenanceComments: addAndUpdateComment(
        state.maintenanceComments,
        payload.comment,
        payload.isEdit,
      ),
    }),
  ),
  on(MaintenanceActions.FinishCheckListSuccess, (state) => ({
    ...state,
    maintenanceComments: initialState.maintenanceComments,
  })),
);

export function mclReducer(state, action): MclState {
  return _maintenanceReducer(state, action);
}

export function normalizeChecklist(list: GetMaintenanceChecklistCommandResult) {
  return {
    equipment_number: list.equipment_number,
    cleaning: list.cleaning?.map((x) => x.id),
    visual: list.visual?.map((x) => x.id),
    lubrication: list.lubrication?.map((x) => x.id),
    checklist: list.checklist?.map((x) => x.id),
  };
}

export function getChecklistItems(list: GetMaintenanceChecklistCommandResult) {
  const checklistItems = {};
  list.cleaning?.forEach((x) => {
    if (x.id) checklistItems[x.id] = x;
  });
  list.visual?.forEach((x) => {
    if (x.id) checklistItems[x.id] = x;
  });
  list.lubrication?.forEach((x) => {
    if (x.id) checklistItems[x.id] = x;
  });
  list.checklist?.forEach((x) => {
    if (x.id) checklistItems[x.id] = x;
  });
  return checklistItems;
}

function toggleChecklistItemDone(itemId: number, state: MclState) {
  return {
    ...state.checklistItems,
    [itemId]: {
      ...state.checklistItems[itemId],
      done: !state.checklistItems[itemId]['done'],
    },
  };
}

function addAndUpdateComment(
  currentState: MclComment[],
  newComment: MclComment,
  isEdit = false,
): MclComment[] {
  const commentExists = currentState.find(
    (mclComment) =>
      mclComment.created_at.getTime() === newComment.created_at.getTime(),
  );
  if (commentExists) {
    return currentState.map(
      (mclComment: MclComment): MclComment =>
        mclComment.created_at.getTime() === newComment.created_at.getTime() &&
        isEdit
          ? ((newComment.created_at = new Date()), newComment)
          : mclComment,
    );
  } else return [...currentState, newComment];
}
